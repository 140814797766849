<template>
  <div class="has-tab-bar">
    <app-bar
      title=""
      :show-back-button="true"
      back-button-path="/profile/cards"
    ></app-bar>
    <spinner-loader v-if="loading"></spinner-loader>
    <div v-if="card" class="container">
      <template v-if="mode === 'edit'">
        <h1>Strippenkaart aanpassen</h1>
        <h2>Naam</h2>
        <input-field
          v-model="editCard.name"
          default-value="Naam van je strippenkaart"
          class="mb-6"
        />

        <h2>Beschrijving</h2>
        <text-field
          v-model="editCard.description"
          class="mb-6"
          default-value="Waarvoor kun je deze strippenkaart gebruiken? En wat zijn de voorwaarden voor gebruik?"
        />
        <div class="sm:space-x-2 sm:flex">
          <button
            class="button button-primary sm:w-1/2 w-full"
            @click="saveCard"
          >
            Opslaan
          </button>
          <button
            class="button button-danger sm:w-1/2 w-full mt-2 sm:mt-0"
            @click="expireCard"
          >
            Inactief maken
          </button>
        </div>
      </template>
      <template v-else>
        <h1>{{ card.name }}</h1>
        <div class="flex mb-4">
          <span
            v-if="!card.active"
            class="rounded-full bg-gray-700 text-white text-sm py-0.5 px-2"
          >
            Inactief
          </span>
        </div>
        <div class="flex">
          <div>
            <nl2br v-if="card.description" tag="p" :text="card.description" />
            <div v-if="card.address" class="flex mt-4">
              <icon-base class="mr-2"><icon-pin /></icon-base>
              <div class="flex-1 text-sm">
                {{ card.address.address }} {{ card.address.city }}
              </div>
            </div>
            <div class="font-bold mt-4">{{ $n(card.cost, 'currency') }}</div>
            <div class="text-sm text-gray-500">
              {{ card.details && card.details.times_to_use }}x te gebruiken
            </div>
          </div>
        </div>

        <template v-if="hasPurchasedCard">
          <div class="text-sm border border-green-600 p-2 rounded-lg mt-4">
            Je hebt deze kaart gekocht op {{ card.purchased_at }}
            <div class="font-bold mt-2">
              Je hebt nog {{ card.remaining }} strippen over
            </div>
          </div>
        </template>
        <template v-else-if="isCardOwner">
          <div class="flex flex-wrap mt-8">
            <div class="w-full sm:pr-2 sm:w-1/2 mb-4">
              <button
                class="button button-secondary button-icon w-full"
                :disabled="!card.purchased_by.length"
                @click="
                  card.purchased_by.length ? (showPurchasedBy = true) : null
                "
              >
                <icon-activity class="mr-2" />
                <div class="text-center flex-1">
                  {{ card.purchased_by.length }}x aangeschaft
                </div>
              </button>
            </div>
            <div class="w-full sm:pl-2 sm:w-1/2 mb-4">
              <button
                :disabled="!card.active"
                class="button button-secondary button-icon w-full"
                @click="$router.push(`${card.id}?mode=edit`)"
              >
                <icon-settings class="mr-2" />
                <div class="text-center flex-1">aanpassen</div>
              </button>
            </div>
          </div>
        </template>
        <button
          v-if="!isCardOwner && !hasPurchasedCard"
          class="button button-primary w-full mt-8"
          @click="gotoPayment"
        >
          Koop nu
        </button>
      </template>
    </div>
    <tab-bar selected="" />
    <modal
      :show="showPurchasedBy"
      :show-close-icon="true"
      @close="showPurchasedBy = false"
    >
      <template v-slot:header>Gekocht door</template>
      <div>
        <div
          v-for="user in card.purchased_by"
          :key="user.id"
          class="flex content-center my-4"
        >
          <div>
            <initials-avatar
              v-if="user.avatarUrl === null || user.avatarUrl === undefined"
              :first-name="user.firstname"
              :last-name="user.lastname"
              :size="16"
            />
            <div
              v-else
              class="mb-4 w-16 h-16 rounded-full bg-cover bg-center inline-block mr-4"
              :style="{
                backgroundImage: `url('${user.avatarUrl}')`
              }"
            />
          </div>
          <div class="flex-1">
            <h2 class="mt-0">{{ user.firstname }} {{ user.lastname }}</h2>
            <div class="flex text-sm text-gray-600">
              <div class="flex-1">Gekocht op: {{ user.purchased_at }}</div>
              <div>
                {{ user.details.remaining }} /
                {{ card.details.times_to_use }} resterend
              </div>
            </div>
          </div>
        </div>
      </div>
    </modal>
    <modal
      :show="showUpdateConfirmModal"
      :show-close-icon="true"
      @close="closeConfirmModal"
    >
      <template v-slot:header>Gelukt</template>
      <p>Je strippenkaart is bijgewerkt!</p>
    </modal>
    <modal
      :show="showAreYouSureModal"
      :show-close-icon="true"
      @close="showAreYouSureModal = false"
    >
      <template v-slot:header>Weet je het zeker?</template>
      <p>
        Als je deze kaart inactief maakt kan deze niet meer aangeschaft worden.
        De gebruikers die nog ongebruikte strippen hebben kunnen deze nog wel
        gebruiken tot de strippenkaart verlopen is
      </p>
      <template v-slot:footer>
        <div class="flex space-x-2 justify-between w-full">
          <button
            class="button button-secondary w-full"
            @click="showAreYouSureModal = false"
          >
            Annuleren
          </button>
          <button
            class="button button-danger w-full"
            @click="closeAreYouSureModal"
          >
            Doorgaan
          </button>
        </div>
      </template>
    </modal>
  </div>
</template>

<script>
import AppBar from '@/components/AppBar'
import SpinnerLoader from '@/components/SpinnerLoader'
import TabBar from '@/components/TabBar'
import Modal from '@/components/Modal'
import InputField from '@/components/InputField'
import TextField from '@/components/TextField'
import InitialsAvatar from '@/components/InitialsAvatar'
import { mapGetters, mapState } from 'vuex'
import { CREDITCARD_GET_ONE } from '@/store/actions/creditcard'
import IconBase from '@/components/IconBase.vue'
import IconPin from '@/components/icons/IconPin.vue'
import IconActivity from '../../components/icons/IconActivity.vue'
import IconSettings from '../../components/icons/IconSettings.vue'
import Nl2br from 'vue-nl2br'
import { CREDITCARD_UPDATE } from '../../store/actions/creditcard'

export default {
  name: 'CreditcardDetailPage',
  components: {
    AppBar,
    SpinnerLoader,
    TabBar,
    IconBase,
    IconPin,
    IconActivity,
    IconSettings,
    Nl2br,
    Modal,
    InitialsAvatar,
    InputField,
    TextField
  },
  data() {
    return {
      cardId: parseInt(this.$route.params.id),
      showPurchasedBy: false,
      mode: this.$route.query.mode,
      editCard: {},
      showUpdateConfirmModal: false,
      showAreYouSureModal: false,
      origin: this.$route.query.origin
    }
  },
  computed: {
    ...mapGetters(['authId']),
    ...mapState({
      card: (state) => state.creditcard.selected,
      loading: (state) => state.creditcard.loading
    }),
    isCardOwner: function () {
      return this.card.owner_id === parseInt(this.authId)
    },
    hasPurchasedCard: function () {
      return !!this.card.purchased_at
    }
  },
  watch: {
    card(card) {
      this.editCard = { ...card }
    },
    '$route.query.mode'(m) {
      this.mode = m
    }
  },
  created: function () {
    this.$store.dispatch(CREDITCARD_GET_ONE, {
      creditcardId: this.cardId
    })
  },
  methods: {
    gotoPayment: function () {
      this.$router.push(`/cards/${this.cardId}/purchase?origin=${this.origin}`)
    },
    saveCard: function () {
      this.$store.dispatch(CREDITCARD_UPDATE, this.editCard)
      this.$store.dispatch(CREDITCARD_GET_ONE, {
        creditcardId: this.cardId
      })
      this.showUpdateConfirmModal = true
    },
    closeConfirmModal: function () {
      this.showUpdateConfirmModal = false
      this.$router.push(`/cards/${this.cardId}`)
    },
    expireCard: function () {
      this.showAreYouSureModal = true
    },
    closeAreYouSureModal: function () {
      this.showAreYouSureModal = false
      this.$store.dispatch(CREDITCARD_UPDATE, {
        ...this.card,
        active: false
      })
      this.$router.push(`/cards/${this.cardId}`)
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/assets/css/variables.scss';
</style>
